<template>
  <div class="productContent">
    <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">产品详情</div>
    <p class="title">产品基本信息</p>
    <div class="card">
        <div class="catalog">
            <p>产品名称</p>
            <p>村民贷</p>
        </div>
        <div class="catalog">
            <p>产品编号</p>
            <p>5808</p>
        </div>
        <div class="catalog">
            <p>产品适用银行</p>
            <p>柳州银行</p>
        </div>
        <div class="catalog">
            <p>产品类型</p>
            <p>其他</p>
        </div>   
        <div class="catalog">
            <p>产品类型描述</p>
            <p>无</p>
        </div>
        <div class="catalog">
            <p>是否为绿色金融产品</p>
            <p>否</p>
        </div>   
        <div class="catalog">
            <p>产品介绍</p>
            <p>以行政村为单位，对村民进行集中批量营销及准入筛选后发放的用于满足其生产经营的快速房贷</p>
        </div>
         
    </div>
    <p class="title">融资信息</p>
    <div class="card">
        <div class="catalog">
            <p>利率水平</p>
            <p>在同期LPR的基础上合理确定利率水平</p>
        </div>
        <div class="catalog">
            <p>融资额度</p>
            <p>1万元-50万元</p>
        </div>
        <div class="catalog">
            <p>币种</p>
            <p>CNY</p>
        </div>
        <div class="catalog">
            <p>融资期限</p>
            <p>1个月至60个月</p>
        </div>   
    </div>
  </div>
</template>
<script>
export default {
   setup() {
    return { 

     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    .card {
        padding: 2vw 4vw;
        .catalog {
            border-bottom: 1px solid rgba(0,0,0,.1);
            padding: 3vw 0;
            display: flex;
            p:first-child {
                flex:1;
                color: #666666;
                font-size: 4vw;
            }
            p:last-child {
                flex:2;
                font-size: 4vw;
                padding-left: 4vw;
            }
        }
        .catalog:last-child {
            border-bottom: 0;
        }
    }
    
}
</style>
